<template>
  <div ref="map" class="map">
    <div
      ref="sticky"
      :class="{ bottom: bottom }"
      :style="{ top: fixed ? `${-top}px` : '' }"
      class="sticky"
    >
      <NuxtErrorBoundary @error="() => {}">
        <MtkMap
          ref="mtkMap"
          v-model:hide-smart-scroll-overlay="hideSmartScrollOverlay"
          :center="mapCenter"
          :bounds="bounds"
          @map-init="ensureMapBounds"
        >
          <MtkMapObjectMarker
            v-for="item in config?.items"
            :key="item.config?.id || 'default'"
            :coords="item.config?.coords ?? undefined"
            :highlighted="item.config?.id === activeItemId"
            @marker-click="
              item.config?.id && displayInfoWindowForItem(item.config.id)
            "
          />
        </MtkMap>

        <div v-if="activeItem" class="popup">
          <TeaserList
            :content="activeItem.content"
            :text="activeItem.text"
            :fig="activeItem.fig"
            :banner="activeItem.banner"
            :buttons="{
              ...activeItem.buttons,
              config: {
                hasCloser: true,
                id: activeItem.buttons?.config?.id,
                moduleType: activeItem.buttons?.config?.moduleType,
              },
            }"
            @close="activeItemId = null"
          />
        </div>
      </NuxtErrorBoundary>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BaseCoords } from '@models/BaseCoords';
import type { AllNullable, Nullable } from '@models/CustomUtilityTypes';
import { convertBaseCoordsToLatLngLike } from '@utils/convertBaseCoords';
import { useIntersectionObserver, useElementBounding } from '@vueuse/core';
import { MediaType } from '../../../../assets/scss/variables';
import type { Map } from './models';
import type { List } from '@components/Teaser/List/models';

const instanceConfig = useWhlInstanceConfig();

const { config } = defineProps<Map>();

const emit = defineEmits(['marker-click']);

const globalStore = useGlobalStore();
const redrawMapBus = useEventBus<never>('redrawMap');

const mapCenter = ref<BaseCoords | undefined>(undefined);
const bounds = ref<{ lat: number; lon: number }[] | undefined>(undefined);

const map = ref<HTMLElement | null>(null);
const sticky = ref<HTMLElement | null>(null);

const fixed = ref<boolean>(false);
const bottom = ref<boolean>(false);

const redrawMap = () => {
  /* only execute if client side */
  if (import.meta.client) {
    /* if is containerSMmax */
    if ([MediaType.TY, MediaType.SM].includes(globalStore.state.mediaType)) {
      map.value?.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
redrawMapBus.on(redrawMap);

const ensureMapBounds = (): void => {
  if (isDefined(config) && !isEmpty(config?.items)) {
    const ne: AllNullable<BaseCoords> = { latitude: null, longitude: null };
    const sw: AllNullable<BaseCoords> = { latitude: null, longitude: null };

    (config.items as List[]).forEach((item: List) => {
      if (item.config?.coords) {
        if (!ne.latitude || item.config.coords.latitude > ne.latitude) {
          ne.latitude = item.config.coords.latitude;
        }
        if (!ne.longitude || item.config.coords.longitude > ne.longitude) {
          ne.longitude = item.config.coords.longitude;
        }
        if (!sw.latitude || item.config.coords.latitude < sw.latitude) {
          sw.latitude = item.config.coords.latitude;
        }
        if (!sw.longitude || item.config.coords.longitude < sw.longitude) {
          sw.longitude = item.config.coords.longitude;
        }
      }
    });

    bounds.value = [
      convertBaseCoordsToLatLngLike(sw as BaseCoords),
      convertBaseCoordsToLatLngLike(ne as BaseCoords),
    ];
  }
};

/* this observer is for the upper edge of map container */
useIntersectionObserver(
  map,
  ([entry]) => {
    if (!entry || !sticky.value) return;

    /* if the top of the container is leaving viewport from the top */
    if (
      entry.boundingClientRect.bottom > sticky.value.clientHeight &&
      entry.boundingClientRect.top < 0
    ) {
      fixed.value = true;
    } else {
      fixed.value = false;
    }
  },
  { rootMargin: '0px 0px -100% 0px' }
);

/* this observer is for the lower edge of map container */
useIntersectionObserver(
  map,
  ([entry]) => {
    if (!entry || !sticky.value) return;

    /* if the bottom of the container is entering viewport from the bottom */
    if (entry.boundingClientRect.bottom < sticky.value.clientHeight) {
      fixed.value = false;
      bottom.value = true;
    } else if (entry.boundingClientRect.top < 0) {
      fixed.value = true;
      bottom.value = false;
    }
  },
  { rootMargin: '-100% 0px 0px 0px' }
);

const { top } = useElementBounding(map);

const activeItemId = ref<Nullable<number>>(null);
watch(
  () => config?.items,
  () => {
    ensureMapBounds();
  },
  { immediate: true }
);

// watch for change from outside list (desktop)
watch(
  () => config?.externalActiveItemId,
  (newValue) => {
    activeItemId.value = newValue;

    const item = config?.items?.find((item) => item.config?.id === newValue);
    if (item?.config?.coords) {
      mapCenter.value = item.config.coords;
    } else {
      mapCenter.value = instanceConfig.value.map.defaultCoords;
    }
  },
  { immediate: true }
);

const hideSmartScrollOverlay = ref(false);
const displayInfoWindowForItem = (itemId: number): void => {
  activeItemId.value = itemId;
  if (activeItemId.value) {
    // prevent display of smart scroll modal in map
    // reset to false after 3 seconds in MtkMap component
    hideSmartScrollOverlay.value = true;
    // emit event to parent component (which is active on desktop and handles scrolling of list)

    emit('marker-click', itemId);
  }
};

const activeItem = computed(() => {
  const currentId = toValue(activeItemId);
  return config?.items?.find((item) => item.config?.id === currentId);
});
</script>

<!-- call this component MapWrap, because Map is not usable -->
<style src="./Map.scss" scoped lang="scss"></style>
